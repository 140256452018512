<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="auth-header">
            <buttonLink :destination="'/'" :vegangLogo="true"> </buttonLink>
            <button-link
              :isInternal="true"
              v-if="loggedIn && userType == 'supplier'"
              :content="' Go To Supplier Dashboard'"
              :destination="'/supplier'"
              :filled="true"
            >
            </button-link>
            <button-link
              :isInternal="true"
              v-if="!loggedIn"
              :content="' Sign-up as dropshipper'"
              :destination="'/register/retailer'"
              :filled="true"
            >
            </button-link>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-page for-retailer">
      <div class="formbox">
        <div class="authpage-header">
          <h1 class="title for-retailer">
            <span class="green-text">Hi, </span>Welcome back
            <span class="sub for-retailer"
              >Login to dropshipper dashboard to manage products & orders.</span
            >
          </h1>

          <div class="auth-box">
            <h2 class="form-title">Go to Your Dashboard</h2>
            <div class="errorMessage-container" v-if="showAuthError">
              <div class="material-icons error-icon">cancel</div>
              <div class="error-message">
                Ops! something happened
                <span>{{ errorMessage }}</span>
              </div>
            </div>
            <div class="form-body">
              <div class="form">
                <CustomInput
                  type="email"
                  id="email"
                  icon="email"
                  label="Email "
                  v-model="login.email"
                  :required="true"
                />
                <CustomPasswordInput
                  type="password"
                  id="password"
                  icon="key"
                  label="Password"
                  v-model="login.password"
                />
                <button class="vgang-button form-button" @click="handleLogin">
                  Login
                </button>
                <router-link :to="'/forgot-pwd/retailer'">
                  <span class="forgot-pass">Forgot Password?</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// Components
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
// Services
import { uuid } from "vue-uuid";
import CommonData from "@/services/CommonData";
import { setVariable, getVariable } from "@/utils/conditionalStoreVariables";
export default {
  metaInfo: {
    title: "vgang|login-retailer",
    meta: [
      {
        name: "description",
        content: "Login to dropshipper dashboard to manage products & orders",
      },
    ],
  },
  data() {
    return {
      userType: "",
      showAuthError: false,
      errorMessage: "",
      uuid: uuid.v1(),
      login: {
        email: "",
        password: "",
        DeviceId: this.$uuid.v4(),
        userType: "retailer",
        service: "vgang",
      },
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
    buttonLink,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.checkRole();
    }
  },
  methods: {
    checkRole() {
      const user = getVariable("user");
      if (user) {
        CommonData.getCurrentUser()
          .then(function (response) {
            this.userType = response.data.role;
            setVariable("user", this.userType);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        return;
      }
    },
    handleLogin() {
      this.message = "";
      this.submitted = true;

      setVariable("role", "retailer");
      this.$store.dispatch("auth/login", this.login).then(
        (data) => {
          this.$router.push({ name: "Retailer" });

          this.successful = true;
          this.showAuthError = false;
        },
        (error) => {
          this.successful = false;
          this.showAuthError = true;
          this.errorMessage = error.data.message;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  font-size: 14px;
}
.formbox {
  //width: 100%;
  @media (max-width: 400px) {
    width: 300px;
  }
}
</style>
